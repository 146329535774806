import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Badge } from "reactstrap"

import { Link } from "react-router-dom"

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logoDarkSm from "../../assets/images/logo/dark/36x36.png"
import logoDarkLg from "../../assets/images/logo/dark-wm/96x96.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import { toggleLeftmenu, changeSidebarType } from "../../store/actions"
import { GetInstitution, Institution } from "helpers/authentication/institution"
import AuthUser from "helpers/authentication/authUser"
import authHeader from "helpers/authentication/auth-token-header"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const { menus } = props
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgentData?.platform)
  useEffect(() => {
    props.changeSidebarType("condensed", true)
  }, [])

  const [roleName, setRoleName] = useState(authHeader().AuthUser.userRoleName)
  useEffect(() => {
    setRoleName(authHeader().AuthUser.userRoleName)
  }, [menus])
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile)
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile)
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoDarkSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoDarkLg}
                    alt=""
                    height="60"
                    style={{ maxWidth: "50px" }}
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoDarkSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoDarkLg}
                    alt=""
                    height="50"
                    style={{ maxWidth: "50px" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            {/* <h4>Cluster.Africa</h4> */}
            {/* <div className="d-none d-lg-inline-block ml-1"> 
            <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                }}
                className="btn header-item  waves-effect"
              >
              <a href="https://www.appzonegroup.com/about" target="_blank">{props.t("About Us")}</a>
              </button>
            </div>
            <div className="d-none d-lg-inline-block ml-1"> 
            <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                }}
                className="btn header-item  waves-effect"
              >
              <Link to="/transaction-dashboard">{props.t("TSD")}</Link>
              </button>
            </div>
            <div className="d-none d-lg-inline-block ml-1"> 
            <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                }}
                className="btn header-item  waves-effect"
              >
                <Link to="/transaction-dashboard">{props.t("Request to be an Agent/ANM/State Rep")}</Link>
              </button>
            </div> */}
          </div>
          <div className="d-flex" style={{ alignItems: "center" }}>
            <div className="d-flex dropdown d-none d-lg-inline-block ml-1">
              <button
                style={{ alignItems: "center" }}
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect d-flex"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />
            <Link to="/profile">
              <Badge
                style={{ marginLeft: "10px" }}
                className={"font-size-12 badge-soft-pink"}
                color={"pink"}
                pill
              >
                {roleName}
              </Badge>
            </Link>
            {Institution.IsRemote() ? (
              <Link to="/remote">
                <Badge
                  style={{ marginLeft: "10px" }}
                  className={"font-size-12 badge-soft-info"}
                  color={"info"}
                  pill
                >
                  {GetInstitution().name == "All Institutions"
                    ? "Remote Home"
                    : GetInstitution().name}
                </Badge>
              </Link>
            ) : (
              <Badge
                style={{ marginLeft: "10px" }}
                className={"font-size-12 badge-soft-info"}
                color={"info"}
                pill
              >
                {GetInstitution().name == "All Institutions"
                  ? "Remote Home"
                  : GetInstitution().name}
              </Badge>
            )}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  menus: PropTypes.array,
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout

  const { menus } = state.permissions
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, menus }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
