import { post } from "helpers/api_helper";
import authHeader, { setAuthUser } from "helpers/authentication/auth-token-header";
import { GetDefaultMenu } from "helpers/authentication/institution";
import ClusterStorage from "helpers/storage-helper";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunctionsByUser, getMenu } from "store/actions";

export const useRemoting = (history?:any) => {
    const [loading, setLoading] = useState(false)
    const [menuLoading, setMenuLoading] = useState(false)
    const { menus } = useSelector(state => (state as any).permissions)
    const [done, setDone] = useState(false)
    const dispatch = useDispatch();
    const [apiError, setError] = useState()

    const onSubmit = (institutionId) => {
        setLoading(true)
        post("/api/v1/auth/remoteinstitution/" + institutionId, {
            institutionId: institutionId,
        })
            .then(async (response: any) => {
                setLoading(false)
                setAuthUser(JSON.stringify(response))
                setMenuLoading(true)

                ClusterStorage.removeItem("menuItems")
                ClusterStorage.removeItem("functionItems")
                ClusterStorage.removeItem("functionItemsForUser")
                dispatch(getMenu(
                    response?.authUser?.institutionId,
                    response?.authUser?.userName,
                    response?.authUser?.userCategory
                ))
                setDone(true)
            })
            .catch(error => {
                setLoading(false)
                setError(error.message)
                console.error("There was an error!", error)
            })
    }


    useEffect(() => {
        setMenuLoading(false)
        if (menus.length > 0) {
            dispatch(getFunctionsByUser());
            if (authHeader().AuthUser && done) {
                if (history) {
                    setTimeout(() => {
                        history.push(GetDefaultMenu(menus))
                    }, 1000)
                }
            }
        }
    }, [menus])

    return { handleSubmit: onSubmit, loading, error: apiError, menuLoading, completed: done, setCompleted:()=> setDone(!done) }
}
