import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label } from "reactstrap"

import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import ClusterForm from "components/Common/Form"
import {
  APPLICABILITY,
  COMMISSIONTYPE,
  COSTBEARER,
  FEETYPE,
  PAYMENTMODE
} from "helpers/variables"
import withFormValidation from "components/HOCs/WithFormValidation"
import withMinimize from "components/HOCs/WithMinimize"
import Logic from "../../commission-structure"
import { Utils } from "helpers/utility"
import { TransactionTypes } from "enums/transaction-types"
import Loading from "components/Common/Loading"
import { EDIT_COMMISSIONSTRUCTURE } from "pages/AgentCommissionMgt/permission"
import { Formik } from "formik"
import { CommissionType } from "enums/commission-type"
import { validator } from "../Validators"
const CommissionStructureForm: React.FC<any> = props => {
  let {
    errorList,
    isFormInvalid,
    editLoading,
    setErrorList,
    handleOnChange,
    handleValidSubmit,
    cashOutInstitutions,
    values
  } = props
  const { MapCashOutInstitutionToDropdown } = Logic;
  const [feeType, setFeeType] = useState()
  const [commissionType, setCommissionType] = useState()


  const initiate = () => {
    return {
      Name: "",
      TransactionType: "",
      IssuingCashOutInstitutionID: "",
      Bearer: "",
      Applicability: "",
      PaymentMode: "",
      CommissionType: "",
      FeeType: "",
      Percentage: "",
      MinimumFee: "",
      MaximumFee: "",
      MaximumServiceProviderFee: "",
      FlatAmount: "",
      MinimumAmount: "",
      MaximumAmount: "",
      BankShare: "",
      AgentShare: "",
      ANMShare: "",
      ServiceProviderShare: "",
      VAT: "",
      BankIncomeAccount: "",
      BankExpenseAccount: "",
      BankSuspenseAccount: "",
      VatPayableAccount: ""
    }
  }

  const numericFields = ["FlatAmount", "Percentage", "VAT", "MinimumFee", "MaximumFee", "MinimumAmount", "MaximumAmount", "AgentShare", "ANMShare", "BankShare", "ServiceProviderShare", "IssuingCashOutInstitutionID"]

  return (
    <Formik
      initialValues={initiate()}
      onSubmit={(values, { resetForm }) => {
        let processedValues = { ...values }
        Object.keys(values).forEach((fieldName) => {
          if (processedValues[fieldName] === "" && numericFields.includes(fieldName)) {
            processedValues[fieldName] = 0
          }
        })
        handleValidSubmit({ ...processedValues })
      }}
      validate={validator}
    >
      {({
        values,
        touched,
        isValid,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        status,
      }) => (
        <ClusterForm handleSubmit={handleSubmit}>
          <React.Fragment>
            <Card
              outline
              style={{ background: "transparent", borderColor: "#999999" }}
              className="border"
            >
              <CardBody>
                <Row>
                  <Col lg={3} md={6} sm={12} style={{ 'paddingTop': '35px' }}>
                    <Row>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          focus
                          label={"Name"}
                          id={"name"}
                          name={"Name"}
                          defaultValue={values?.Name}
                          type="text"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.Name}
                          validationError={errors?.Name}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label>Transaction Type</Label>
                          <ClusterDropdown
                            useValue
                            placeholder={"- Select -"}
                            id={"transactionType"}
                            items={Utils.Enums.toOptions(TransactionTypes)}
                            name="TransactionType"
                            value={values?.TransactionType}
                            required={true}
                            onChange={(name, value) => {
                              setFieldValue("TransactionType", TransactionTypes[value]);
                            }}
                            validationError={errors?.TransactionType}
                          />
                        </FormGroup>
                      </Col>
                      {Utils.Enums.compare(TransactionTypes, values?.TransactionType, TransactionTypes.POSAgentCashOut) ?
                        Utils.List.isEmpty(cashOutInstitutions) ? <Loading></Loading> :
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Label>Issuing CashOut Institution</Label>
                              <ClusterDropdown
                                useValue
                                placeholder={"- Select -"}
                                id={"issuingCashOutInstitution"}
                                value={cashOutInstitutions.find(inst => inst.ID == values.IssuingCashOutInstitution?.ID)?.BankCode}
                                name={"IssuingCashOutInstitutionID"}
                                items={MapCashOutInstitutionToDropdown(cashOutInstitutions)}
                                onChange={(name, value) => {
                                  setFieldValue("IssuingCashOutInstitutionID", value)
                                }}
                                validationError={errors?.IssuingCashOutInstitutionID}
                              />
                            </FormGroup>
                          </Col> : null}

                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label>Cost Bearer</Label>
                          <ClusterDropdown
                            useValue
                            placeholder={"- Select -"}
                            items={COSTBEARER}
                            name="Bearer"
                            id={"costBearer"}
                            onChange={(name, value) => {
                              setFieldValue("Bearer", value)
                            }}
                            value={values?.Bearer}
                            required={true}
                            validationError={errors?.Bearer}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label>Applicability</Label>
                          <ClusterDropdown
                            useValue
                            placeholder={"- Select -"}
                            items={APPLICABILITY}
                            id={"applicability"}
                            name={"Applicability"}
                            onChange={(name, value) => {
                              setFieldValue("Applicability", value);
                            }}
                            value={values?.Applicability}
                            required={true}
                            validationError={errors?.Applicability}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label>Payment Mode</Label>
                          <ClusterDropdown
                            useValue
                            placeholder={"- Select -"}
                            items={PAYMENTMODE}
                            id={"paymentMode"}
                            name={"PaymentMode"}
                            onChange={(name, value) => {
                              setFieldValue("PaymentMode", value)
                            }}
                            required={true}
                            value={values?.PaymentMode}
                            validationError={errors?.PaymentMode}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <Row>
                      <Col lg={12} md={12}>
                        <p>
                          <b>Fees</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label>Commission Type</Label>
                          <ClusterDropdown
                            useValue
                            items={COMMISSIONTYPE}
                            id={"commissionType"}
                            name={"CommissionType"}
                            required={true}
                            value={values?.CommissionType}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              setCommissionType(value)
                              setFieldValue("MaximumAmount", "")
                              setFieldValue("MinimumAmount", "")
                              handleChange(name)
                            }}
                            validationError={errors?.CommissionType}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label>Fee Type</Label>
                          <ClusterDropdown
                            useValue
                            items={FEETYPE}
                            id={"feeType"}
                            name="FeeType"
                            required={true}
                            value={values?.FeeType}
                            onChange={(name, value) => {
                              setFieldValue(name, value)
                              setFeeType(value)
                            }}
                            validationError={errors?.FeeType}
                          />
                        </FormGroup>
                      </Col>

                      {values?.FeeType == "Percentage" &&
                        <React.Fragment>
                          <Col lg={12} md={12}>
                            <ClusterInput
                              label={"Percentage"}
                              id={"percentage"}
                              name={"Percentage"}
                              required={true}
                              type="number"
                              onChange={(name, value) => {
                                setFieldValue("Percentage", value)
                                setFieldValue('FlatAmount', "")
                                handleChange(name)
                              }}
                              onBlur={handleBlur}
                              value={values?.Percentage}
                              validationError={errors?.Percentage}
                            />
                          </Col>

                          <Col lg={6} md={6}>
                            <ClusterInput
                              value={values?.MinimumFee}
                              label={"Minimum Fee"}
                              id={"minimumFee"}
                              name={"MinimumFee"}
                              type="number"
                              onChange={(name, value) => {
                                handleChange(name)
                                if (value == '') {
                                  setFieldValue(name, 0)
                                }
                              }}
                              onBlur={handleBlur}
                              validationError={errors?.MinimumFee}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              value={values?.MaximumFee}
                              label={"Maximum Fee"}
                              id={"maximumFee"}
                              name={"MaximumFee"}
                              type="number"
                              onChange={(name, value) => {
                                handleChange(name)
                                if (value == '') {
                                  setFieldValue(name, 0)
                                }
                              }}
                              onBlur={handleBlur}
                              validationError={errors?.MaximumFee}
                            />
                          </Col>

                          <Col lg={6} md={6}>
                            <ClusterInput
                              value={values?.MaximumServiceProviderFee}
                              label={"Maximum Service Provider Fee"}
                              id={"maximumServiceProviderFee"}
                              name={"MaximumServiceProviderFee"}
                              type="number"
                              onChange={(name, value) => {
                                handleChange(name)
                                if (value == '') {
                                  setFieldValue(name, 0)
                                }
                              }}
                              onBlur={handleBlur}
                              validationError={errors?.MaximumServiceProviderFee}
                            />
                          </Col>

                        </React.Fragment>}

                      {values?.FeeType == "Flat" &&
                        <Col lg={12} md={12}>
                          <ClusterInput
                            value={values?.FlatAmount}
                            label={"Flat Amount"}
                            id={"flatAmount"}
                            name={"FlatAmount"}
                            type="number"
                            onChange={(name, value) => {
                              setFieldValue("FlatAmount", value)
                              setFieldValue("Percentage", "")
                              setFieldValue("MinimumFee", "")
                              setFieldValue("MaximumFee", "")
                              handleChange(name)
                            }}
                            onBlur={handleBlur}
                            validationError={errors?.FlatAmount}
                          />
                        </Col>
                      }
                      {values?.CommissionType == "Range" && (
                        <React.Fragment>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              value={values?.MinimumAmount}
                              label={"Minimum Amount"}
                              id={"minimumAmount"}
                              name={"MinimumAmount"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.MinimumAmount}
                            />
                          </Col>

                          <Col lg={6} md={6}>
                            <ClusterInput
                              value={values?.MaximumAmount}
                              label={"Maximum Amount"}
                              id={"maximumAmount"}
                              name={"MaximumAmount"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.MaximumAmount}
                            />
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Row>
                      <Col lg={12} md={12}>
                        <p>
                          <b>Shares</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.BankShare}

                          label={
                            "Bank Share(" +
                            (values?.FeeType == "Percentage" ? "%" : "₦") +
                            ")"
                          }
                          id={"bankShare"}
                          name={"BankShare"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.BankShare}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.AgentShare}
                          label={
                            "Agent Share(" +
                            (values?.FeeType == "Percentage" ? "%" : "₦") +
                            ")"
                          }
                          id={"agentShare"}
                          name={"AgentShare"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.AgentShare}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.ANMShare}
                          label={
                            "ANM Share(" +
                            (values?.FeeType == "Percentage" ? "%" : "₦") +
                            ")"
                          }
                          id={"anmShare"}
                          name={"ANMShare"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.ANMShare}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.ServiceProviderShare}
                          label={
                            "Service Provider Share(" +
                            (values?.FeeType == "Percentage" ? "%" : "₦") +
                            ")"
                          }
                          id={"serviceProviderShare"}
                          name={"ServiceProviderShare"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.ServiceProviderShare}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.VAT}
                          label={"VAT (%)"}
                          id={"vat"}
                          name={"VAT"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.VAT}
                          required={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Row>
                      <Col lg={12} md={12}>
                        <p>
                          <b>Account Info</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.BankIncomeAccount}
                          label={"Bank Income Account"}
                          id={"bankIncomeAccount"}
                          name={"BankIncomeAccount"}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.BankIncomeAccount}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.BankExpenseAccount}
                          label={"Bank Expense Account"}
                          id={"bankExpenseAccount"}
                          name={"BankExpenseAccount"}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.BankExpenseAccount}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.BankSuspenseAccount}
                          label={"Bank Suspense Account"}
                          id={"bankSuspenseAccount"}
                          name={"BankSuspenseAccount"}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.BankSuspenseAccount}
                          required={false}
                        />
                      </Col>
                      <Col lg={12} md={12}>
                        <ClusterInput
                          value={values?.VatPayableAccount}
                          label={"VAT Account"}
                          id={"vatAccount"}
                          name={"VatPayableAccount"}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.VatPayableAccount}
                          required={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "0px" }}>
                  <Col
                    lg={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <ClusterButton
                      permissions={[EDIT_COMMISSIONSTRUCTURE]}
                      className="btn-sm"
                      loading={editLoading}
                      disabled={!touched
                        ? true
                        : !isValid}
                      color={"primary"}
                      addon={"bx bx-save"}
                    >
                      Add To Configuration
                    </ClusterButton>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        </ClusterForm>)}
    </Formik>
  )
}
CommissionStructureForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  scopeLoading: PropTypes.bool,
  agentDetails: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withMinimize(withFormValidation(CommissionStructureForm)))

